
:root {
  --primary-blue-color: #0074cc;
  --secondary-blue-color: #00428a;
  --red-color: #e74c3c;
  --green-color: #27ae60;
  --white-color: #fffffe;
  --light-blue-background: #f0f7fc;
  --black-color: #0f0e17;
  --yellow-color: #ff8906;
}

/* Footer.scss */

#footer {
  background-color: var(--black-color);
  padding: 4rem 0;
  display: flex;
  justify-content: space-between;

  .footer-section {
    flex: 1;
    margin-right: 2rem;
    color: var(--white-color);

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;
      }

      a {
        text-decoration: none;
        color: var(--white-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    address {
      font-style: normal;
      line-height: 1.5;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input[type="email"] {
        margin-bottom: 1rem;
        padding: 0.5rem;
        border: 1px solid var(--white-color);
        border-radius: 4px;
        width: 100%;
      }

      button[type="submit"] {
        background-color: var(--primary-blue-color);
        color: var(--white-color);
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }
    }
  }
}

.copywrite {
  text-align: center;
  flex: 1;
  padding: 20px;
  background: var(--black-color);
  color: var(--white-color);
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.copywrite p {
  margin: 0;
}