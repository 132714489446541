.whatsappIcon {
    position: absolute;
    bottom: 8rem;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .callIcon {
    position: absolute;
    bottom:4rem;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .conImg{
    cursor: pointer;
    height:50px;
  }
.whatsappIcon.show,
.callIcon.show {
  opacity: 1;
}
#whatsapp {
  height: 50px;
}