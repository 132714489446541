:root {
  --primary-blue-color: #0074cc;
  --secondary-blue-color: #00428a;
  --red-color: #e74c3c;
  --green-color: #27ae60;
  --white-color: #fffffe;
  --light-blue-background: #f0f7fc;
  --black-color: #0f0e17;
  --yellow-color: #ff8906;
}

* {
  padding: 0;
  margin: 0;
}

.about {
  height: 40rem;
  background-color: var(--black-color);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .whatsapp {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    right: 20px;
  }
  .he {
    background-color: var(--black-color);

    #Know {
      color: #cacaca;
      font-size: 5rem;
      text-align: center;
      margin-top: 7rem;
      width: 50%;
    }
    #whoarewe {
      color: #cacaca;
      font-size: 1.3rem;
      text-align: center;
      margin-top: 7rem;
      width: 50%;
    }

    #land {
      height: 400px;
      border-radius: 50%;
      margin-top: 4rem;
    }
  }

  .ourMission {
    display: flex;
    padding: 8rem 0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .ourMissionText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      overflow: hidden;

      #ourMission {
        margin-bottom: 1rem;
      }

      #ourMissionText {
        width: 60%;
      }
    }

    .ourMissionPhoto {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      .tesing {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30rem;
        width: 30rem;
        border-radius: 50%;
        background-color: var(--yellow-color);
        #im {
          height: 28rem;
          border-radius: 50%;
        }
      }
    }
  }

  .ourStory {
    background-color: var(--black-color);
    padding: 8rem 0;
    display: flex;
    flex-direction: row-reverse;
    // margin-bottom: 10rem;

    .storyText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      overflow: hidden;
      #story {
        margin-bottom: 1rem;
        color: var(--white-color);
      }
      #storyText {
        color: var(--white-color);
        width: 60%;
      }
    }
    .ourMissionPhoto {
      display: flex;
      padding: 8rem 0;
      justify-content: center;
      align-items: center;
      width: 50%;

      .tesing {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23rem;
        width: 23rem;
        border-radius: 50%;
        background-color: var(--yellow-color);

        #im {
          height: 25rem;
          // margin-top: 4.5rem;
          border-radius: 50%;
        }
      }
    }
  }
  @keyframes jump {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(
        0
      ); /* Start and end with no translation (no jump) */
    }
    40% {
      transform: translateY(-20px); /* Jump up */
    }
    60% {
      transform: translateY(-20px); /* Jump down */
    }
  }
  .ourCommmunity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;
    color: var(--black-color);
    .community {
      display: flex;
      //   flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      #community {
      }
    }
    .cardss {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10rem;
      .card {
        height: 480px;
        width: 350px;
        border: 1px solid var(--yellow-color);
        display: flex;
        align-items: center;
        // justify-content: center;
        cursor: pointer; /* Disable the animation on hover */
        flex-direction: column;
        #cardImage {
          height: 80px;
          margin-top: 2rem;
          transition: transform 0.3s ease-in-out;
        }
        #cardContent {
          width: 90%;
          margin-top: 1rem;
        }
        &:hover {
          #cardImage {
            transform: translateY(
              0
            ); /* Reset the transform property on hover */
            animation: jump 1s infinite; /* Apply the "jump" animation infinitely on hover */
          }
        }
      }
    }
  }

  @media screen and (max-width: 898px) {
    .he {
      #Know {
        margin-top: 6rem;
        margin-bottom: 0rem;
        width: 100%;
        font-size: 4rem;
      }
      #whoarewe {
        width: 90%;
      }
    }

    .ourMission {
      flex-direction: column;
      padding: 4rem 0;
      width: 100%;

      .ourMissionText {
        width: 100%;

        #ourMission {
          margin-left: 0;
          font-size: 2.4rem;
        }

        #ourMissionText {
          margin-left: 0;
          font-size: 1rem;
          width: 85%;
        }
      }

      .ourMissionPhoto {
        .tesing {
          background-color: #fff;
          #im {
            margin-top: 1rem;
            height: 20rem;
            border-radius: 50%;
          }
        }
      }
    }
    .ourStory {
      padding: 4rem 0;
      margin-bottom: 2rem;
      flex-direction: column-reverse;

      .storyText {
        width: 100%;
        #story {
          font-size: 2.4rem;
        }
        #storyText {
          font-size: 1rem;
          width: 85%;
        }
      }
      .ourMissionPhoto {
        .tesing {
          margin-left: 35%;
          background-color: var(--black-color);
          #im {
            border: 1px blackvar(--yellow-color);
            background-color: var(--yellow-color);
            margin-left: 45%;
            margin-top: 4.6rem;
            height: 20rem;
            border-radius: 50%;
          }
        }
      }
    }
    .ourCommmunity {
      color: var(--black-color);
      padding: 4rem 0;
      .cardss {
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
}
