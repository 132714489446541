:root {
  --primary-blue-color: #0074cc;
  --secondary-blue-color: #00428a;
  --red-color: #e74c3c;
  --green-color: #27ae60;
  --white-color: #fffffe;
  --light-blue-background: #f0f7fc;
  --black-color: #0f0e17;
  --yellow-color: #ff8906;
}

@mixin card-styles {
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.4s ease;
}

.services {
 
  .whatsapp {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    right: 20px;
   
  }
  .ourServices {
    height: 40rem;
    background-color: var(--black-color);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    #grd,
    #grdi {
      height: 40rem;
    }

    #land {
      height: 400px;
      border-radius: 50%;
      margin-top: 4rem;
    }
  }

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0 10rem;
    @media (max-width: 768px) {
      margin:0;
      flex-direction: column;
    }

    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .service-card {
        width: 30%;
        margin: 1rem;

        @media (max-width: 768px) {
          width: 90%;
          margin: 1rem 0;
        }

        @include card-styles;
        padding: 2rem;

        .service-card .card-content {
          border-top: 2px solid #ff8906;
          padding-top: 1rem;
        }

        .service-card h5 {
          color: #ff8906;
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .service-card p {
          color: #333333;
          font-size: 1rem;
          line-height: 1.4;
          padding: 1rem;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
    }
  }
  // Define your default styles for larger screens

  .whyChooseUs {
    background-color: var(--black-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    padding: 8rem 0;
    // margin-bottom: 10rem;

    #whyChoosseusT {
    //   padding: 1rem 0;
      margin-bottom:3rem;
    }

    #whyChoose {
      display: flex;
      align-items: center;
      justify-content: center;

      #whyChooseText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        overflow: hidden;

        #whyText {
          width: 60%;
        }
      }
    }

    .whyTextPhoto {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      .testing {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30rem;
        width: 30rem;
        border-radius: 50%;
        background-color: var(--yellow-color);

        #ima {
          height: 28rem;
          border-radius: 50%;
        }
      }
    }
  }
  .startProject {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;
    padding:8rem 0;
    .ourProjectText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      #ourProjectText {
        margin-top: 2rem;
        margin-bottom:2rem;
      }
      #ourProjectPara {
        width: 70%;
        margin-bottom: 3rem;
      }
    }
    .workWithUs {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    .whyChooseUs {
      padding: 5rem 0;
      #whyChoosseusT {
        font-size: 2.5rem;
      }
      #whyChoose {
        flex-direction: column;
        text-align: center;

        #whyChooseText {
          width: 100%;
          font-size: 1rem;
          margin-bottom: 2rem;
          #whyText {
            font-size: 1rem;
            width: 90%;
          }
        }
      }

      .whyTextPhoto {
        width: 100%;

        .testing {
          height: 18rem;
          width: 18rem;
          #ima {
            height: 16rem;
          }
        }
      }
    }
    #ourProjectText {
      font-size: 2.5rem;
    }
    #ourProjectPara{
        width: 100%;
        font-size: 1rem;
    }
  }
}
