:root {
  --primary-blue-color: #0074cc;
  --secondary-blue-color: #00428a;
  --red-color: #e74c3c;
  --green-color: #27ae60;
  --white-color: #fffffe;
  --light-blue-background: #f0f7fc;
  --black-color: #0f0e17;
  --yellow-color: #ff8906;
}
* {
  padding: 0;
  margin: 0;
}

.Career {
  height: 40rem;
  background-color: var(--black-color);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .whatsapp {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    right: 20px;
  }

  .hes {
    background-color: var(--black-color);

    #Know {
      color: #cacaca;
      font-size: 5rem;
      text-align: center;
      margin-top: 7rem;
      width: 50%;
      @media screen and (max-width: 768px) {
        font-size: 4rem;
        width: 100%;
      }
    }
    #whoarewe {
      color: #cacaca;
      font-size: 1.3rem;
      text-align: center;
      margin-top: 7rem;
      width: 50%;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        width: 90%;
      }
    }

    #land {
      height: 400px;
      border-radius: 50%;
      margin-top: 4rem;
    }
  }

  .wearehiring {
    display: flex;
    padding: 8rem 0;
    align-items: center;
    justify-content: center;

    .ourMissionText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      overflow: hidden;

      #ourMission {
        margin-bottom: 1rem;
      }

      #ourMissionText {
        width: 60%;
      }
    }

    .ourMissionPhoto {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      .tesing {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30rem;
        width: 30rem;
        border-radius: 50%;
        background-color: var(--yellow-color);
        #im {
          height: 28rem;
          border-radius: 50%;
        }
      }
    }
  }
  .hiringProcess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 0;
    background-color: var(--black-color);
    color: var(--white-color);

    .cards {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;
      #hideit {
        display: none;
      }
      .cardcontent {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--white-color);
        height: 16rem;
        width: 16rem;
        border-radius: 50px;
      }
    }
  }
  .ApplyNow {
    display: flex;
    padding: 8rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    // .form {
    //   margin-top: 1rem;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 1rem;
    //   align-items: center;
    //   justify-content: center;
    //   padding: 4rem 2rem;
    //   border: 1px solid var(--yellow-color);
    //   border-radius: 10px;
    //   .submitButton {
    //     padding: 1rem 15rem;
    //     border-radius: 10px;
    //     background-color: var(--yellow-color);
    //   }
    // }
    .whatsup {
      display: flex;
      gap: 5rem;
      justify-content: center;
      align-items: center;
      margin: 4rem;
      #socialMedia{
        height:100px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .wearehiring {
      flex-direction: column;
      padding: 4rem 0;
      width: 100%;

      .ourMissionText {
        width: 100%;

        #ourMission {
          margin-left: 0;
          font-size: 2.4rem;
        }

        #ourMissionText {
          margin-left: 0;
          font-size: 1rem;
          width: 85%;
        }
      }

      .ourMissionPhoto {
        padding-top: 0.5rem;
        .tesing {
          background-color: var(--white-color);

          #im {
            margin-top: 1rem;
            height: 17rem;
            border-radius: 50%;
          }
        }
      }
    }
    .hiringProcess {
      .cards {
        flex-direction: column;
        gap: 0.8rem;
        #hide {
          display: none;
        }
        #hideit {
          display: block;
        }
      }
    }
    .ApplyNow {
      .whatsup {
        flex-direction: column;
        gap: 2rem;
        margin: 1rem;
      }
    }
  }
}
