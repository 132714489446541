* {
  padding: 0;
  margin: 0;
}
.Contactus {
  height: 40rem;
  background-color: var(--black-color);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .whatsapp {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    right: 20px;
  }

  .he {
    background-color: var(--black-color);

    #Know {
      color: #cacaca;
      font-size: 5rem;
      text-align: center;
      margin-top: 7rem;
      width: 60%;
      @media screen and (max-width: 768px) {
        margin-top: 6rem;
        margin-bottom: 0rem;
        width: 100%;
        font-size: 4rem;
      }
    }
    #land {
      height: 400px;
      border-radius: 50%;
      margin-top: 4rem;
    }
  }
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .centerka {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      .Contact-us {
        display: flex;
        width: 50%;
        padding: 8rem 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--white-color);
        #contact {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;
        }
        .whatsup {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
          flex-direction: column;
          gap: 2rem;
          #socialMedia {
            height: 100px;
            // cursor: pointer;
          }
        }
      }
      .map {
        display: flex;
        width: 50%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .details {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: left;
          #reach {
            text-align: left;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .section {
      flex-direction: column;
      margin-bottom: 4rem;
      .centerka {
        width: 100%;
      flex-direction: column;

        .Contact-us {
          width: 100%;

          #contact {
            width: 70%;
            font-size: 2rem;
          }
          #contactus {
            font-size: 1rem;
            width: 80%;
          }
          .form {
            padding: 4rem 1.5rem;

            .submitButton {
              padding: 1rem 8rem;
            }
          }
        }
        .map {
          .details {
            #reach {
              width: 100%;
              padding: 0.5rem;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
