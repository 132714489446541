.page-transition {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.page-exit {
    opacity: 0;
}

* {
    padding: 0;
    margin: 0;
    font-family: Arial, sans-serif;

}