* {
    padding: 0;
    margin: 0;
  }
  
  .custom-navbar {
    position: sticky;
    top: 0;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 55;
  }
  
  .logo img {
    height: 4.5rem;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .nav-links {
    text-decoration: none;
    color: #a7a9be;
    margin: 0 10px;
    display: flex;
    gap: 2rem;
    transition: max-height 0.5s ease-in-out;
    max-height: 300px;
    overflow: hidden;
  }
  
  #lnk {
    position: relative;
    text-decoration: none;
    color: #a7a9be;
    margin: 0 10px;
    display: flex;
    gap: 2rem;
    cursor: pointer;
    transition: border-bottom 0.3s ease-in-out,transform 0.3s ease-in-out;
  
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0.4rem;
      background-color: #ff8906;
      transition: width 0.3s ease-in-out;
    }
  
    @media (min-width: 600px) {
      &:hover::before {
        width: 100%;
      }
  
      &:hover {
        transform: scale(1.1); /* Add scaling transformation on hover */
      }
    }
  }
  
  
  #menu-button {
    display: none;
  }
  
  #btns {
    padding: 0.5rem 0.9rem;
    border-radius: 10px;
    font-weight: 600;
    background-color: #ff8906;
    color: #fffffe;
    border: none;
    outline: none;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
  
    span {
      display: inline-block;
      transition: font-size 0.2s ease-in-out;
    }
  
    &:hover {
      color: #ffffff;
      transform: scale(1.05);
    }
  
    &:hover span {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 768px) {
    #btns {
      width: 30%;
      margin-bottom: 1rem;
    }
  
    #lnk:hover::before {
      width: 20%;
    }
  
    .nav-links {
      flex-direction: column;
      position: absolute;
      top: 5rem;
      right: -1.2rem;
      background-color: #000000;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      width: 100%;
      z-index: 100;
      transition: max-height 0.4s ease-in-out;
      max-height: 0;
      overflow: hidden;
    }
  
    .nav-links.show {
      max-height: 400px;
    }
  
    #menu-button {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 101;
    }
  }
  