:root {
  --primary-blue-color: #0074cc;
  --secondary-blue-color: #00428a;
  --red-color: #e74c3c;
  --green-color: #27ae60;
  --white-color: #fffffe;
  --light-blue-background: #f0f7fc;
  --black-color: #0f0e17;
  --yellow-color: #ff8906;
}

/* Mixins */
@mixin card-styles {
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.4s ease;
}

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header Styles */
.header {
  background-color: var(--primary-blue-color);
  color: var(--white-color);
  padding: 20px 0;
  text-align: center;
}

.header h1 {
  font-size: 2rem;
  margin: 0;
}

/* Navigation Styles */
.navbar {
  background-color: var(--secondary-blue-color);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.nav-item {
  list-style: none;
}

.nav-link {
  text-decoration: none;
  color: var(--white-color);
  font-weight: bold;
}

/* Home Section Styles */
.home {
  height: 40rem;
  background-color: var(--black-color);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .whatsapp {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    right: 20px;
   
  }
  #land {
    height: 400px;
    border-radius: 50%;
    margin-top: 4rem;
  }
}

#brain {
  color: #cacaca;
  font-size: 5rem;
  width: 50%;
  margin-top: 1rem;
}

#btn {
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 600;
  background-color: #ff8906;
  color: #fffffe;
  border: none;
  outline: none;
}

#grd,
#grdi {
  height: 40rem;
}

.con {
  display: flex;
  margin: 8rem 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding:8rem 0;
  background-color: var(--white-color);
}

#suc {
  font-size: 3.5rem;
  // margin-top:20rem;
  font-weight: 600;
  width: 60%;
  text-align: center;
  color: #0f0e17;
}

#or {
  font-size: 1.3rem;
  margin-top: 0.4rem;
  width: 50%;
  text-align: center;
}

.hireusfor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black-color);
  padding: 10rem 0;
}

#hire {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--white-color);
  margin-top: 2rem;
}

.card-container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.service-card {
  width: 30%;
  @include card-styles;
}

.service-card .card-content {
  border-top: 2px solid #ff8906;
  padding-top: 1rem;
}

.service-card h5 {
  color: #ff8906;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.service-card p {
  color: #333333;
  font-size: 1rem;
  line-height: 1.4;
  padding: 1rem;
}

.service-card:nth-child(1) {
  background-color: var(--white-color);
  padding: 1rem;
}

.service-card:nth-child(2) {
  background-color: var(--white-color);
  padding: 1rem;
}

.service-card:nth-child(3) {
  background-color: var(--white-color);
  padding: 1rem;
}

/* "Our Company" Section Styles */
.ourcomp {
  background-color: var(--white-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
}

#aboutc {
  font-size: 2.7rem;
  font-weight: 600;
  text-align: center;
  color: var(--black-color);
}

#abouto {
  text-align: center;
  width: 80%;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.3rem;
}

.founder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-top: 2rem;
}

.Prince,
.Simran {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  text-align: center;
  transition: .3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

#prince,
#simran {
  margin-top: 1rem;
}

#founder {
  height: 10rem;
  border-radius: 50%;
}

.ourTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding: 10rem 0;
  background-color: var(--black-color);
}

#ourTeamTitle {
  text-align: center;
  font-weight: 600;
  color: var(--white-color);
}

#ourTeamTitleInfo {
  text-align: center;
  margin: 2rem 0;
  color: #a7a9be;
  width: 60%;
}

.team-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  /* Increase the gap for better spacing */
  flex-wrap: wrap;
  margin-top: 2rem;
}

.team-card {
  @include card-styles;
  width: 300px;
  text-align: center;
  position: relative;
}

.team-card:hover {
  transform: scale(1.05);
  //   cursor: pointer;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.team-card .card-info {
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  transition: bottom 0.3s ease;
  padding: 1rem;
  text-align: center;
}
/* Add a transition effect to all buttons */
button {
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

/* Add a hover effect for the buttons */
button:hover {
  background-color: #ff5500; /* Change background color on hover */
  color: #ffffff; /* Change text color on hover */
  transform: scale(1.05); /* Slightly scale up the button on hover */
}

.team-card:hover .card-info {
  bottom: 0;
}

.team-card img {
  width: 100%;
  height: auto;
}

#card-name {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}

#card-desig {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.testimonials-container {
  text-align: center;
  height:40rem;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
}

.carousel {
  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .testimonial-text {
      font-size: 1.2rem;
      width: 50%;
      color: #666;
      margin-bottom: 20px;
    }

    .client-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .client-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 10px;
      }

      .client-name {
        font-size: 1.2rem;
        color: #333;
      }
    }

    @media (max-width: 768px) {
      .testimonial-text {
        font-size: 1rem;
        width: 80%;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    background-color: transparent;
    color: #333;
    font-size: 2rem;
  }
}

/* Footer Styles */
#footer {
  background: var(--black-color);
  padding: 30px 0;
  color: var(--white-color);
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: Arial, sans-serif;
}

.footer-section {
  flex: 1;
  padding: 20px;
  text-align: center;
}

#footer h3 {
  color: var(--white-color);
  font-size: 18px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
  a {
    color: var(--white-color);
    text-decoration: none;
  }
}

address {
  margin: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

input[type="email"] {
  padding: 5px;
  margin-bottom: 5px;
  width: 70%;
}

button[type="submit"] {
  background: var(--primary-blue-color);
  color: var(--white-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background: #005ea6;
}
.copywrite {
  text-align: center;
  flex: 1;
  padding: 20px;
  background: var(--black-color);
  color: var(--white-color);
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.copywrite p {
  margin: 0;
}
/* Media Queries */
@media screen and (max-width: 768px) {
  #suc {
    width: 95%;
    font-size: 2.5rem;
    text-align: center;
  }

  #grdi {
    height: 2vh;
  }

  #brain {
    font-size: 4rem;
  }

  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 80%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 480px) {
  #suc {
    font-size: 1.5rem;
    text-align: center;
  }

  #or {
    font-size: 0.9rem;
    margin-top: 0.4rem;
    width: 80%;
    text-align: center;
  }

  #grdi {
    height: 2vh;
  }

  #brain {
    font-size: 3rem;
    width: 100%;
    margin-top: 13rem;
  }

  #hire {
    margin-top: 0.5rem;
  }

  .service-card {
    width: 90%;
  }

  .service-card h5 {
    font-size: 1rem;
  }

  .service-card p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1199px) {
  #grdi {
    // height: 2vh;
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .con {
    margin: 5rem 0;
  }
  .hireusfor {
    padding: 5rem 0;
  }
  .ourcomp {
    padding: 5rem 0;
  }
  #land {
    display: none;
  }
  .founder {
    flex-direction: column;
    gap: 1rem;
  }

  .Prince,
  .Simran {
    text-align: center;
  }

  .team-cards {
    flex-direction: column;
    gap: 1rem;
  }

  #aboutc {
    font-size: 2em;
  }

  #abouto {
    font-size: 1em;
    width: 80%;
    text-align: left;
  }
  .ourTeam {
    padding: 5rem 0;
  }

  #ourTeamTitle {
    font-size: 2em;
  }

  #ourTeamTitleInfo {
    font-size: 1em;
    width: 90%;
    text-align: left;
  }

  #card-name {
    text-align: center;
    font-size: 0.6em;
  }

  #card-desig {
    font-size: 0.5em;
  }

  .team-card {
    width: 80%;
  }

  #footer {
    flex-direction: column;
    gap: 1rem;
  }
}
